<template>
  <v-dialog v-model="modalData.dialog" persistent scrollable max-width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        v-if="permissionCan('create')"
      >
        {{ $t("FORMS.new_currency") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="formModel.id"
                :rules="idRules"
                :label="$t('FORMS.currency')"
                :id="dynamicID"
                :disabled="!formModel.edited"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="formModel.sign"
                :rules="signRules"
                :label="$t('FORMS.sign')"
                :id="dynamicID"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="formModel.exchange_rate"
                :rules="exchangeRateRules"
                :label="$t('FORMS.exchange_rate')"
                :id="dynamicID"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <!-- v-if="formModel.format" -->
              <v-text-field
                v-model="formModel.format"
                :label="$t('FORMS.format')"
                :id="dynamicID"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && formModel.edited) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CurrencyForm",
  props: ["modalData", "permissions"],
  data() {
    return {
      formValid: false,
      selectedLocale: this.modalData.selectedLocale,
      exchangeRateRules: [
        (v) => !!v || "Exchange Rate is required",
        (v) => v > 0 || "Exchange Rate must be greater than 0",
      ],
      idRules: [
        (v) => !!v || "Id is required",
        (v) => v.length == 3 || "Id must be 3 characters",
      ],
      signRules: [
        (v) => !!v || "Sign is required",
        (v) => v.length < 4 || "Sign must be max 3 characters",
      ],
    };
  },
  computed: {
    formTitle() {
      return this.modalData.editedIndex === -1
        ? this.$t("MENU.NEW")
        : this.$t("FORMS.edit") + this.formModel.id;
    },
    formModel() {
      return this.modalData.editedItem;
    },
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
  },

  methods: {
    handleCloseModalForm() {
      this.$refs.form.resetValidation();
      this.$emit("closeModalForm");
    },
    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.$refs.form.resetValidation();
        this.$emit("saveModalForm", this.formModel);
      }
    },
    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "system.currency." + action
      );
    },
  },
};
</script>
